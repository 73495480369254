<template>
  <div id="main__section" class="section1">
    <div class="background-container">
      <div class="slider" :style="{ transform: `translateX(-${currentBackgroundIndex * 100}%)` }">
        <img class="slide" src="@/assets/images/main/section1--bg1.png" />
        <img class="slide" src="@/assets/images/main/section1--bg2.png" />
        <img class="slide" src="@/assets/images/main/section1--bg3.png" />
      </div>
    </div>
    <div id="main__section--inline">
      <div class="inline--1">
        <p class="inline__text--main">HOME</p>
      </div>
      <div class="inline--2"></div>
      <div class="inline--3">
        <p class="inline__text--sub">HOME_________________01</p>
      </div>
      <div class="inline--4"></div>
      <div class="inline--5"></div>
    </div>
    <div class="section1__body">
      <div class="section1__maintext">
        <h1>Best</h1>
        <h1>Sourcing</h1>
        <h1>Partners</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div id="screen__pc" class="section1__subtext">
        <p>{{ $t('mainSec1.text--1') }}</p>
        <p>{{ $t('mainSec1.text--2') }}</p>
      </div>
      <div id="screen__mobile" class="section1__subtext">
        <p>{{ $t('mainSec1__mobile.text--1') }}</p>
        <p>{{ $t('mainSec1__mobile.text--2') }}</p>
        <p>{{ $t('mainSec1__mobile.text--3') }}</p>
      </div>
      <div class="controls">
        <button class="arrow left" @click="prevBackground"></button>
        <button class="arrow right" @click="nextBackground"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSec1",
  data() {
    return {
      currentBackgroundIndex: 0,
      interval: null,
    };
  },
  methods: {
    startAutoSlide() {
      // console.log('Starting auto slide');
      if (this.interval) {
        clearInterval(this.interval);  // Clear any existing interval
      }
      this.interval = setInterval(this.nextBackground, 3000);
    },
    stopAutoSlide() {
      // console.log('Stopping auto slide');
      clearInterval(this.interval);
    },
    nextBackground() {
      // console.log('Next background');
      this.currentBackgroundIndex = (this.currentBackgroundIndex + 1) % 3;
      this.resetTimer();
    },
    prevBackground() {
      // console.log('Previous background');
      this.currentBackgroundIndex = (this.currentBackgroundIndex - 1 + 3) % 3;
      this.resetTimer();
    },
    resetTimer() {
      // console.log('Resetting timer');
      this.stopAutoSlide();
      this.startAutoSlide();
    }
  },
  created() {
    // console.log('Component mounted');
    this.startAutoSlide();
  },
  beforeUnmount() {
    // console.log('Component before unmount');
    this.stopAutoSlide();
  }
};
</script>

<style scoped>
.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background-container::before {
  width: 100vw;
  height: 100%;
}

.slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
}



.slide {
  flex: 1 0 100%;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.controls {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.arrow {
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow.left::before,
.arrow.right::before {
  content: "";
  border: solid var(--font-color-default);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 7px;
  border-radius: 3px;
}

.arrow.left::before {
  transform: rotate(135deg);
}

.arrow.right::before {
  transform: rotate(-45deg);
}
</style>