<template>
  <div id="main__section" class="section4">
    <div id="main__section--inline">
      <div class="inline--1">
        <p class="inline__text--main">BUSINESS</p>
      </div>
      <div class="inline--2"></div>
      <div class="inline--3">
        <p class="inline__text--sub">BUSINESS_________________03</p>
      </div>
      <div class="inline--4"></div>
      <div class="inline--5"></div>
    </div>
    <div id="screen__pc" class="section4__textbox">
      <div class="section4__maintext">
        <h1>Our<br />Business</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section4__subtext">
        <p>{{ $t('mainSec4.text--1') }}</p>
      </div>
    </div>
    <div id="screen__pc" class="section4__content">
      <div v-for="(cardTitle, i) in cardTitles" :class="`section4__content--card card--${i}`" :key="i">
        <div class="card__detail">
          <h3>{{ cardTitle }}</h3>
          <p v-html="cardDetails[i]"></p>
        </div>
      </div>
    </div>
    <div id="screen__mobile" class="section4__textbox">
      <div class="section4__maintext">
        <h1 id="screen__pc">Our<br />Business</h1>
        <h1 id="screen__mobile">Our Business</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section4__subtext">
        <p>{{ $t('mainSec4__mobile.text--1') }}</p>
        <p>{{ $t('mainSec4__mobile.text--2') }}</p>
      </div>
    </div>
    <div id="screen__mobile" class="section4__content">
      <div class="section4__content--cards" ref="cardsContainer">
        <div v-for="(title, index) in extendedCardTitles" :key="index"
          :class="`section4__content--card card--${index % cardTitles.length}`">
          <div class="card__detail">
            <h3>{{ title }}</h3>
            <p v-html="extendedCardDetails[index]"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSec4",
  data() {
    return {
      isAdjusting: false, // Flag to prevent scroll event conflicts
    };
  },
  computed: {
    cardTitles() {
      return [
        this.$t('mainSec4.cardTitle--1'),
        this.$t('mainSec4.cardTitle--2'),
        this.$t('mainSec4.cardTitle--3'),
      ];
    },
    cardDetails() {
      return [
        this.$t('mainSec4.cardDetail--1'),
        this.$t('mainSec4.cardDetail--2'),
        this.$t('mainSec4.cardDetail--3'),
      ];
    },
    extendedCardTitles() {
      return [...this.cardTitles, ...this.cardTitles, ...this.cardTitles];
    },
    extendedCardDetails() {
      return [...this.cardDetails, ...this.cardDetails, ...this.cardDetails];
    },
  },
  mounted() {
    this.setInitialScrollPosition();
    this.setupInfiniteScroll();
  },
  methods: {
    setInitialScrollPosition() {
      const container = this.$refs.cardsContainer;
      const cardWidth = container.firstElementChild.offsetWidth;
      container.scrollLeft = cardWidth * this.cardTitles.length;
    },
    setupInfiniteScroll() {
      const container = this.$refs.cardsContainer;
      const cardWidth = container.firstElementChild.offsetWidth;
      const numCards = this.cardTitles.length;
      const maxScrollLeft = cardWidth * (numCards * 2);

      container.addEventListener("scroll", () => {
        if (this.isAdjusting) return;

        if (container.scrollLeft >= maxScrollLeft) {
          this.isAdjusting = true;
          container.scrollLeft = cardWidth * numCards;
          setTimeout(() => { this.isAdjusting = false; }, 100);
        } else if (container.scrollLeft <= 0) {
          this.isAdjusting = true;
          container.scrollLeft = cardWidth * numCards - 1;
          setTimeout(() => { this.isAdjusting = false; }, 100);
        }
      });

      let isScrolling;
      container.addEventListener("scroll", () => {
        if (this.isAdjusting) return;

        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          if (container.scrollLeft % cardWidth !== 0) {
            this.isAdjusting = true;
            const closestIndex = Math.round(container.scrollLeft / cardWidth);
            container.scrollTo({
              left: closestIndex * cardWidth,
              behavior: 'smooth'
            });
            setTimeout(() => { this.isAdjusting = false; }, 500);
          }
        }, 66);
      });
    },
  },
};
</script>
