<template>
  <!-- <div id="main__section" class="section6"> -->
  <div id="screen__pc" class="section6">
    <div class="section6__layout">
      <div class="section6__infocard">
        <img src="@/assets/images/logo--color.svg" alt="" />
        <div class="section6__infocard--sub">
          <p>{{ $t('mainSec6.text--1') }}</p>
          <div>
            <span>{{ $t('mainSec6.text--2') }}</span>
            <p>556 - 87 - 03200</p>
          </div>
        </div>
        <p class="section6__infocard--cr">
          Copyright 2024 bspglobal. All rights Reserved.
        </p>
      </div>
      <div class="section6__details">
        <div class="section6__details--info">
          <span>Contact</span>
          <p>02 - 568 - 4158</p>
        </div>
        <div class="section6__details--info">
          <span>Fax</span>
          <p>0504 - 066 - 0369</p>
        </div>
        <div class="section6__details--info">
          <span>Email</span>
          <p>contact@bspgbl.com</p>
        </div>
        <div class="section6__details--info">
          <span>Address</span>
          <p>{{ $t('mainSec6.text--3') }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="screen__mobile" class="section6">
    <div class="section6__layout">
      <div class="section6__infocard">
        <img src="@/assets/images/logo--color.svg" alt="" />
        <div class="section6__infocard--sub">
          <p>{{ $t('mainSec6.text--1') }}</p>
        </div>

      </div>
      <div class="section6__details">
        <div class="section6__details--info">
          <span>Contact</span>
          <p>02 - 568 - 4158</p>
        </div>
        <div class="section6__details--info">
          <span>Fax</span>
          <p>0504 - 066 - 0369</p>
        </div>
        <div class="section6__details--info">
          <span>Email</span>
          <p>contact@bspgbl.com</p>
        </div>
        <div class="section6__details--info">
          <span>Address</span>
          <p>{{ $t('mainSec6.text--3') }}</p>
        </div>
        <div class="section6__details--info">
          <span>{{ $t('mainSec6.text--2') }}</span>
          <p>556 - 87 - 03200</p>
        </div>
      </div>
      <p class="section6__infocard--cr">
        Copyright 2024 bspglobal. All rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainSec6",
  data() {
    return {};
  },
};
</script>
