<template>
    <div id="main__section" class="section5 contact__depth" style="height: 100vh;">
        <div id="main__section--inline">
            <div class="inline--1">
                <p class="inline__text--main">CONTACT</p>
            </div>
            <div class="section5__inline--2"></div>
            <div class="inline--3">
                <p class="inline__text--sub">CONTACT________________05</p>
            </div>
            <div class="inline--4"></div>
            <div class="inline--5"></div>
        </div>
        <div class="section5__textbox">
            <div class="section5__maintext">
                <h1 id="screen__pc">Contact<br />Us</h1>
                <h1 id="screen__mobile">Contact Us</h1>
                <h1 class="section__textline"></h1>
            </div>
            <div id="screen__pc" class="section5__subtext">
                <p>{{ $t('mainSec5.text--1') }}</p>
            </div>
            <div id="screen__mobile" class="section5__subtext">
                <p>{{ $t('mainSec5__mobile.text--1') }}<br />{{ $t('mainSec5__mobile.text--2') }}</p>
            </div>
        </div>
        <form class="contact__form" @submit.prevent="handleSubmit">
            <div class="contact__form--group">
                <div class="contact__form--label">
                    <label for="company">{{ $t('mainSec5.formTitle--1') }}</label>
                    <p></p>
                </div>
                <input type="text" id="company" v-model="form.company" />
            </div>
            <div class="contact__form--group">
                <div class="contact__form--label">
                    <label for="name">{{ $t('mainSec5.formTitle--2') }}</label>
                    <p>*</p>
                </div>
                <input type="text" id="name" v-model="form.name" required />
            </div>
            <div class="contact__form--group">
                <div class="contact__form--label">
                    <label for="contact">{{ $t('mainSec5.formTitle--3') }}</label>
                    <p>*</p>
                </div>
                <input type="text" id="contact" v-model="form.contact" required />
            </div>
            <div class="contact__form--group">
                <div class="contact__form--label">
                    <label for="email">{{ $t('mainSec5.formTitle--4') }}</label>
                    <p>*</p>
                </div>
                <input type="email" id="email" v-model="form.email" required />
            </div>
            <div class="contact__form--group contact__form--detail">
                <div class="contact__form--label">
                    <label for="message">{{ $t('mainSec5.formTitle--5') }}</label>
                    <p>*</p>
                </div>
                <textarea id="message" v-model="form.message" @input="handleInput" maxlength="300" required
                    style="resize: none; overflow: auto"></textarea>
                <div class="char__counter">
                    {{ remainingChars }}
                </div>
            </div>
            <div class="contact__form--group contact__form--check">
                <div class="contact__form--checkbox">
                    <input type="checkbox" id="privacy" v-model="form.privacyAgreement" required />
                    <label @click="toggleAgreeModal()">
                        <span style="font-weight: 500; color: var(--font-color-default);">
                            {{ $t('mainSec5.formAgree--1') }}
                        </span>
                        {{ $t('mainSec5.formAgree--2') }}
                    </label>
                </div>
                <button type="submit" class="submit__button">
                    SUBMIT
                    <div class="submit__button--sub">></div>
                </button>
            </div>
        </form>
    </div>
    <MainSec6 />
    <AgreeModal v-if="agreeModalOn" :isOpen="agreeModalOn" @close="toggleAgreeModal" />
</template>

<script>
import emailjs from "emailjs-com";
import MainSec6 from "@/components/main/MainSec6.vue";
import AgreeModal from "@/components/sub/AgreeModal.vue";

export default {
    name: "MainSec5",
    components: {
        MainSec6,
        AgreeModal
    },
    data() {
        return {
            form: {
                company: "",
                name: "",
                contact: "",
                email: "",
                message: "",
                privacyAgreement: false,
            },
            agreeModalOn: false,
            maxChars: 300,
        };
    },
    computed: {
        remainingChars() {
            return this.maxChars - this.form.message.length;
        },
    },
    methods: {
        handleSubmit() {
            const serviceID = "service_bk0rrdv";
            const templateID = "template_1qtb75c";
            const userID = "Ml8DlxUXgOZQnl7HS";

            const templateParams = {
                company: this.form.company,
                name: this.form.name,
                contact: this.form.contact,
                email: this.form.email,
                message: this.form.message,
            };

            emailjs.send(serviceID, templateID, templateParams, userID).then(
                (response) => {
                    console.log("SUCCESS!", response.status, response.text);
                    alert("Your message has been sent successfully!");
                    this.resetForm();
                },
                (error) => {
                    console.error("FAILED...", error);
                    alert("There was an error sending your message.");
                }
            );
        },
        handleInput() {
            if (this.form.message.length > this.maxChars) {
                this.form.message = this.form.message.substring(0, this.maxChars);
            }
        },
        resetForm() {
            this.form.company = "";
            this.form.name = "";
            this.form.contact = "";
            this.form.email = "";
            this.form.message = "";
            this.form.privacyAgreement = false;
        },
        toggleAgreeModal() {
            this.agreeModalOn = !this.agreeModalOn;
        },
    },
};
</script>