<template>
  <div id="main__section" class="section2">
    <div id="main__section--inline" class="screen__pc">
      <div class="inline--1">
        <p class="inline__text--main">SUSTAINABILITY</p>
      </div>
      <!-- <div class="inline--2"></div> -->
      <div class="inline--3">
        <p class="inline__text--sub">SUSTAINABILITY_________________02</p>
      </div>
      <div class="inline--4"></div>
      <div class="inline--5"></div>
    </div>
    <div class="section2__body">
      <div class="section2__maintext">
        <h1>Sustainble Growth</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section2__subtext">
        <p>{{ $t('mainSec2.text--1') }}</p>
      </div>
    </div>
    <div id="screen__pc" class="section2__content">
      <div v-for="(contentTitle, i) in contentTitles" :class="`section2__content--box box--${i}`" :key="i">
        <img :class="`box--image box--image${i}`" :data-src="contentImages[i]" />
        <h3 v-html="contentTitle"></h3>
        <h4 v-html="contentSubTitles[i]"></h4>
        <p v-html="contentDetails[i]"></p>
      </div>
    </div>
    <div id="screen__mobile" class="section2__content">
      <div v-for="(contentTitle, i) in contentTitles" :class="`section2__content--box box--${i}`" :key="i">
        <img :class="`box--image box--image${i}`" :data-src="contentImages[i]" />
        <h4 v-html="contentSubTitles[i]"></h4>
      </div>
    </div>
  </div>
</template>

<script>
import image1 from "@/assets/images/main/section2--content1.png";
import image2 from "@/assets/images/main/section2--content2.png";
import image3 from "@/assets/images/main/section2--content3.png";
import image4 from "@/assets/images/main/section2--content4.png";
import image5 from "@/assets/images/main/section2--content5.png";

export default {
  name: "MainSec2",
  data() {
    return {
      contentTitles: [
        "customer<br>oriented",
        "excellence and<br>professionalism",
        "advanced product and<br>service",
        "passion",
        "trust",
      ],
      contentImages: [image1, image2, image3, image4, image5],
    };
  },
  computed: {
    contentSubTitles() {
      return [
        this.$t('mainSec2.boxTitle--1'),
        this.$t('mainSec2.boxTitle--2'),
        this.$t('mainSec2.boxTitle--3'),
        this.$t('mainSec2.boxTitle--4'),
        this.$t('mainSec2.boxTitle--5')
      ];
    },
    contentDetails() {
      return [
        this.$t('mainSec2.boxText--1'),
        this.$t('mainSec2.boxText--2'),
        this.$t('mainSec2.boxText--3'),
        this.$t('mainSec2.boxText--4'),
        this.$t('mainSec2.boxText--5'),
      ];
    }
  },
  mounted() {
    this.lazyLoadImages();
  },
  methods: {
    lazyLoadImages() {
      const images = document.querySelectorAll(".box--image");
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const loadImage = (image) => {
        const src = image.getAttribute("data-src");
        if (!src) {
          return;
        }
        image.src = src;
        image.removeAttribute("data-src");
      };

      const handleIntersect = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage(entry.target);
            observer.unobserve(entry.target);
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersect, options);

      images.forEach((image) => {
        observer.observe(image);
      });
    },
  },
};
</script>
