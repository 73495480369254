<template>
    <div class="subtab__view--content">
        <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/outsourcing--6.png" alt="">
        <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/outsourcing--6--mobile.png" alt="">
        <h1>{{ $t('peopleSubTab2.text--1') }}</h1>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--2') }}</h2>
            <h3 id="screen__pc">{{ $t('peopleSubTab2.text--3') }}</h3>
            <h3 id="screen__mobile">
                {{ $t('peopleSubTab2__mobile.text--1') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--2') }}
            </h3>
            <p>
                {{ $t('peopleSubTab2.text--4') }}
                <br>
                {{ $t('peopleSubTab2.text--5') }}
            </p>
        </div>
        <div id="screen__pc" class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--6') }}</h2>
            <p style="color: #FF4040; padding-bottom: 0;">
                {{ $t('peopleSubTab2.text--7') }}
                <br>
                {{ $t('peopleSubTab2.text--8') }}
                <br>9
                {{ $t('peopleSubTab2.text--9') }}
            </p>
            <p>
                {{ $t('peopleSubTab2.text--10') }}
                <br>
                {{ $t('peopleSubTab2.text--11') }}
                <br>
                {{ $t('peopleSubTab2.text--12') }}
            </p>
        </div>
        <div id="screen__mobile" class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--6') }}</h2>
            <p style="color: #FF4040; padding-bottom: 0;">
                {{ $t('peopleSubTab2__mobile.text--3') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--4') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--5') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--6') }}
            </p>
            <p>
                {{ $t('peopleSubTab2__mobile.text--7') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--8') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--9') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--10') }}
            </p>
        </div>
        <img class="outsourcing--image outsourcing--7" src="../../assets/images/sub/outsourcing--7.png" alt="">
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--13') }}</h2>
            <p id="screen__pc">
                {{ $t('peopleSubTab2.text--14') }}
                <br>
                {{ $t('peopleSubTab2.text--15') }}
                <br>
                {{ $t('peopleSubTab2.text--16') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab2__mobile.text--11') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--12') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--13') }}
                <br>
                {{ $t('peopleSubTab2__mobile.text--14') }}
            </p>
        </div>
        <img class="outsourcing--image outsourcing--8" src="../../assets/images/sub/outsourcing--8.png" alt="">
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--17') }}</h2>
            <p>
                {{ $t('peopleSubTab2.text--18') }}
                <br>
                {{ $t('peopleSubTab2.text--19') }}
            </p>
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--20') }}</h2>
            <img class="outsourcing--image outsourcing--9" src="../../assets/images/sub/outsourcing--9.png" alt="">
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--21') }}</h2>
            <img class="outsourcing--image outsourcing--10" src="../../assets/images/sub/outsourcing--10.png" alt="">
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--22') }}</h2>
            <img v-if="isKoreanLanguage" class="outsourcing--image outsourcing--11"
                src="../../assets/images/sub/outsourcing--11.png" alt="">
            <img v-else class="outsourcing--image outsourcing--11"
                src="../../assets/images/sub/outsourcing--11--en.png">
        </div>
        alt="">
        <img class="outsourcing--image outsourcing--11" src="../../assets/images/sub/outsourcing--12.jpg" alt="">

    </div>
</template>

<script>
export default {
    name: 'PeopleSubTab2',
    computed: {
        isKoreanLanguage() {
            return this.$i18n.locale === 'ko';
        }
    }
}
</script>