<template>
    <div class="subtab__view--content">
        <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/outsourcing--13.png" alt="">
        <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/outsourcing--13--mobile.png" alt="">
        <h1>{{ $t('peopleSubTab3.text--1') }}</h1>
        <img class="outsourcing--image outsourcing--14" src="../../assets/images/sub/outsourcing--14.png" alt="">
        <div class="subtab__view--textbox1" style="gap: 1rem;">
            <h3>{{ $t('peopleSubTab3.text--2') }}</h3>
            <p>
                {{ $t('peopleSubTab3.text--3') }}
                <br>
                {{ $t('peopleSubTab3.text--4') }}
            </p>
            <h3>{{ $t('peopleSubTab3.text--5') }}</h3>
            <p id="screen__pc">
                {{ $t('peopleSubTab3.text--6') }}
                <br>
                {{ $t('peopleSubTab3.text--7') }}
                <br>
                {{ $t('peopleSubTab3.text--8') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab3__mobile.text--1') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--2') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--3') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--4') }}
            </p>
            <h3>{{ $t('peopleSubTab3.text--9') }}</h3>
            <p id="screen__pc">
                {{ $t('peopleSubTab3.text--10') }}
                <br>
                {{ $t('peopleSubTab3.text--11') }}
                <br>
                {{ $t('peopleSubTab3.text--12') }}
                <br>
                {{ $t('peopleSubTab3.text--13') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab3__mobile.text--5') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--6') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--7') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--8') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--9') }}
            </p>
            <h3>{{ $t('peopleSubTab3.text--14') }}</h3>
            <p></p>
        </div>
        <div class="subtab__view--textbox1" style="gap: 1rem; border: none;">
            <h3>{{ $t('peopleSubTab3.text--15') }}</h3>
            <p>
                {{ $t('peopleSubTab3.text--16') }}
                <br>
                {{ $t('peopleSubTab3.text--17') }}
                <br>
                <span id="screen__pc" style="color: #a1a1a1">
                    {{ $t('peopleSubTab3.text--18') }}
                    <br>
                    {{ $t('peopleSubTab3.text--19') }}
                </span>
                <span id="screen__mobile" style="color: #a1a1a1">
                    {{ $t('peopleSubTab3__mobile.text--10') }}
                    <br>
                    {{ $t('peopleSubTab3__mobile.text--11') }}
                    <br>
                    {{ $t('peopleSubTab3__mobile.text--12') }}
                </span>
            </p>
            <h3>{{ $t('peopleSubTab3.text--20') }}</h3>
            <p>
                {{ $t('peopleSubTab3.text--21') }}
                <br>
                {{ $t('peopleSubTab3.text--22') }}
            </p>
            <img class="outsourcing--image outsourcing--15" src="../../assets/images/sub/outsourcing--15.png" alt="">
            <h3>{{ $t('peopleSubTab3.text--23') }}</h3>
            <p>
                {{ $t('peopleSubTab3.text--24') }}
                <br>
                {{ $t('peopleSubTab3.text--25') }}
            </p>
            <h3>{{ $t('peopleSubTab3.text--26') }}</h3>
            <p>{{ $t('peopleSubTab3.text--27') }}</p>
            <h3>{{ $t('peopleSubTab3.text--28') }}</h3>
            <p id="screen__pc">
                {{ $t('peopleSubTab3.text--29') }}
                <br>
                {{ $t('peopleSubTab3.text--30') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab3__mobile.text--13') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--14') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--15') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--16') }}
            </p>
            <h3>{{ $t('peopleSubTab3.text--31') }}</h3>
            <p id="screen__pc">
                {{ $t('peopleSubTab3.text--32') }}
                <br>
                {{ $t('peopleSubTab3.text--33') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab3__mobile.text--17') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--18') }}
                <br>
                {{ $t('peopleSubTab3__mobile.text--19') }}
            </p>
            <img class="outsourcing--image outsourcing--16" src="../../assets/images/sub/outsourcing--16.png" alt="">
            <h3>{{ $t('peopleSubTab3.text--34') }}</h3>
            <p>
                {{ $t('peopleSubTab3.text--35') }}
                <br>
                {{ $t('peopleSubTab3.text--36') }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PeopleSubTab3',
}
</script>