<template>
  <div id="main__section" class="section3">
    <div id="main__section--inline">
      <div class="inline--1">
        <p class="inline__text--main">NEWS</p>
      </div>
      <div class="inline--2"></div>
      <div class="inline--3">
        <p class="inline__text--sub">NEWS_________________04</p>
      </div>
      <div class="inline--4"></div>
      <div class="inline--5"></div>
    </div>
    <div class="section3__textbox">
      <div class="section3__maintext">
        <h1>News<br />BSP<br />Global</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section3__subtext">
        <p>{{ $t('mainSec3.text--1') }}</p>
      </div>
    </div>
    <div class="section3__content">
      <div v-for="post in filteredPosts" :key="post.id" class="section3__content--item">
        <div class="section3__content--date">
          <h2>{{ formatDate(post.created_at).day }}</h2>
          <p>{{ formatDate(post.created_at).date }}</p>
        </div>
        <div class="section3__content--textbox">
          <h3>{{ post.type === "notice" ? $t('mainSec3.notice') : $t('mainSec3.news') }}</h3>
          <p>{{ post.title }}</p>
        </div>
      </div>
    </div>
    <div class="section3__buttons">
      <div class="section3__button">
        <h2>{{ $t('mainSec3.notice') }}</h2>
        <button class="arrow right notice"></button>
      </div>
      <div class="section3__button">
        <h2>{{ $t('mainSec3.news') }}</h2>
        <button class="arrow right notice"></button>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "MainSec3",
  data() {
    return {
      posts: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    displayedPosts() {
      return this.posts.slice(0, 6);
    },
    filteredPosts() {
      if (this.windowWidth <= 993) {
        return this.displayedPosts.slice(0, 4);
      }
      return this.displayedPosts;
    },
  },
  created() {
    this.fetchPosts();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await axios.get(
          "https://bspgbl.com/admin/post/get_posts.php"
        );
        this.posts = response.data.posts;
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return {
        day: ("0" + date.getDate()).slice(-2),
        date: `${date.getFullYear()}.${("0" + (date.getMonth() + 1)).slice(
          -2
        )}`,
      };
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>