<template>
    <div id="sub__page">
        <div :class="['sub__page--textbox', currentTab === 'tab1' ? 'notice__hero' : 'news__hero']">
            <h1 class="sub__page--textkr">{{ tabLabels[currentTab] }}</h1>
            <h1 id="screen__pc" class="section__textline"></h1>
            <p :class="currentTab === 'tab1' ? 'notice__hero--subtext' : ''">{{ currentMessage }}</p>
        </div>
        <TabView :tabs="tabs" @update:currentTab="updateTab">
            <template #tab1>
                <!-- <div class="post__pagenum">Total {{ totalPages['tab1'] }} 페이지</div> -->
                <p class="tabview__title">NOTICE</p>
                <div class="post__list">
                    <div v-if="loading"></div>
                    <div v-if="error">{{ error }}</div>
                    <div v-else>
                        <div v-for="(post, index) in posts['tab1']" :key="post.id" @click="incrementViews(post.id)"
                            class="post__list--item">
                            <p>{{ totalPosts['tab1'] - ((currentPage['tab1'] - 1) * postsPerPage + index) }}</p>
                            <p>{{ post.title }}</p>
                            <p>Admin</p>
                            <p>{{ formatDate(post.created_at) }}</p>
                            <p>{{ post.views }}</p>
                        </div>
                        <PaginationView :totalPages="totalPages['tab1']" v-model:currentPage="currentPage['tab1']" />
                    </div>
                </div>
            </template>
            <template #tab2>
                <!-- <div class="post__pagenum">Total {{ totalPages['tab2'] }} 페이지</div> -->
                <p class="tabview__title">NEWS</p>
                <div class="post__list">
                    <div v-if="loading"></div>
                    <div v-if="error">{{ error }}</div>
                    <div v-else>
                        <div v-for="(post, index) in posts['tab2']" :key="post.id" @click="incrementViews(post.id)"
                            class="post__list--item">
                            <p>{{ totalPosts['tab2'] - ((currentPage['tab2'] - 1) * postsPerPage + index) }}</p>
                            <p>{{ post.title }}</p>
                            <p>Admin</p>
                            <p>{{ formatDate(post.created_at) }}</p>
                            <p>{{ post.views }}</p>
                        </div>
                        <PaginationView :totalPages="totalPages['tab2']" v-model:currentPage="currentPage['tab2']" />
                    </div>
                </div>
            </template>
        </TabView>
    </div>
    <MainSec6 />
</template>

<script>
import axios from 'axios';
import TabView from "@/components/sub/TabView.vue";
import PaginationView from "@/components/sub/PaginationView.vue";
import MainSec6 from "@/components/main/MainSec6.vue";

export default {
    name: 'NewsDepth',
    components: {
        TabView,
        PaginationView,
        MainSec6
    },
    data() {
        return {
            posts: {
                'tab1': [],
                'tab2': []
            },
            loading: true,
            error: null,
            currentTab: 'tab1',
            currentPage: {
                'tab1': 1,
                'tab2': 1
            },
            totalPosts: {
                'tab1': 0,
                'tab2': 0
            },
            postsPerPage: 15,
        };
    },
    computed: {
        tabLabels() {
            return {
                tab1: this.$t('newsDepth.tab--1'),
                tab2: this.$t('newsDepth.tab--2')
            };
        },
        tabs() {
            return [
                { name: 'tab1', label: this.tabLabels.tab1 },
                { name: 'tab2', label: this.tabLabels.tab2 }
            ];
        },
        currentMessage() {
            return this.currentTab === 'tab1'
                ? this.$t('newsDepth.text--1')
                : this.$t('newsDepth.text--2');
        },
        totalPages() {
            return {
                'tab1': Math.ceil(this.totalPosts['tab1'] / this.postsPerPage),
                'tab2': Math.ceil(this.totalPosts['tab2'] / this.postsPerPage)
            };
        }
    },
    watch: {
        currentTab(newTab) {
            this.fetchPosts(newTab);
        },
        currentPage: {
            deep: true,
            handler() {
                this.fetchPosts(this.currentTab);
            }
        }
    },
    created() {
        this.fetchPosts(this.currentTab);
    },
    methods: {
        async fetchPosts(tab) {
            this.loading = true;
            try {
                const endpoint = tab === 'tab1' ? 'get_posts_notice.php' : 'get_posts_news.php';
                const response = await axios.get(`https://bspgbl.com/admin/post/${endpoint}?page=${this.currentPage[tab]}`);
                this.posts[tab] = response.data.posts;
                this.totalPosts[tab] = response.data.total_posts;
            } catch (error) {
                this.error = 'Failed to load posts.';
            } finally {
                this.loading = false;
            }
        },
        updateTab(tab) {
            this.currentTab = tab;
        },
        async incrementViews(postId) {
            console.log('Post clicked:', postId);
            const now = Date.now();
            const lastViewTime = localStorage.getItem(`lastViewTime_${postId}`);

            if (lastViewTime && now - lastViewTime < 60000) {
                console.log('View count update skipped (within 1 minute)');
                return;
            }

            localStorage.setItem(`lastViewTime_${postId}`, now);

            try {
                const response = await axios.post('https://bspgbl.com/admin/post/increment_views.php', { id: postId });
                if (response.data.success) {
                    console.log('View count incremented for post:', postId);
                    this.posts[this.currentTab] = this.posts[this.currentTab].map(post =>
                        post.id === postId ? { ...post, views: Number(post.views) + 1 } : post
                    );
                } else {
                    console.error('Failed to increment views:', response.data.error);
                }
            } catch (error) {
                console.error('Failed to increment views:', error);
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toISOString().slice(2, 10).replace(/-/g, '-');
            const formattedTime = date.toTimeString().slice(0, 5);
            return `${formattedDate} ${formattedTime}`;
        }
    }
};
</script>