<template>
  <div>
    <NavBar />
    <div v-if="isMainPage" class="custom__navigation" :class="{ hidden: hideNavbar, visible: !hideNavbar }">
      <div class="navigation__line"></div>
      <img class="navigation__dot" :style="dotStyle" src="./assets/images/nav--custom.svg" />
    </div>
    <full-page v-if="isMainPage" ref="fullpage" :options="options" @onLeave="handleFullPageLeave"
      @afterLoad="handleAfterLoad">
      <div class="section">
        <MainSec1 />
      </div>
      <div class="section">
        <MainSec2 />
      </div>
      <div class="section">
        <MainSec4 />
      </div>
      <div class="section">
        <MainSec3 />
      </div>
      <div class="section">
        <MainSec5 @updateAgreeModalOn="handleAgreeModalOnUpdate" />
      </div>
      <div class="section fp-auto-height">
        <MainSec6 />
      </div>
    </full-page>
    <router-view v-else></router-view>
    <AgreeModal v-if="agreeModalOn" :isOpen="agreeModalOn" @close="toggleAgreeModal" />
  </div>
</template>

<script>
import "@/assets/css/normalize.css";
import "@/assets/css/setup.css";
import "@/assets/css/main.css";
import "@/assets/css/main_2560.css";
import "@/assets/css/main_1200.css";
import "@/assets/css/main_992.css";
import "@/assets/css/main_768.css";
import "@/assets/css/main_576.css";
import "@/assets/css/sub.css";
import "@/assets/css/sub_992.css";
import "@/assets/css/navbar.css";

import NavBar from "@/components/NavBar.vue";
import AgreeModal from "@/components/sub/AgreeModal.vue";
import MainSec1 from "@/components/main/MainSec1.vue";
import MainSec2 from "@/components/main/MainSec2.vue";
import MainSec3 from "@/components/main/MainSec3.vue";
import MainSec4 from "@/components/main/MainSec4.vue";
import MainSec5 from "@/components/main/MainSec5.vue";
import MainSec6 from "@/components/main/MainSec6.vue";

import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return {
      t,
      locale,
    };
  },
  data() {
    return {
      options: {
        licenseKey: "GM527-MXGM9-KJK48-0JU87-PMQLM",
        navigation: false,
        scrollHorizontally: false,
        lazyLoading: false,
        onLeave: this.handleFullPageLeave,
        afterLoad: this.updateDotPosition,
        scrollingSpeed: 700,
      },
      dotPosition: 0,
      totalSections: 6,
      hideNavbar: false,
      agreeModalOn: false,
    };
  },
  computed: {
    isMainPage() {
      return this.$route.path === "/";
    },
    dotStyle() {
      return {
        top: this.dotPosition - 10 + "%",
      };
    },
  },
  components: {
    NavBar,
    AgreeModal,
    MainSec1,
    MainSec2,
    MainSec3,
    MainSec4,
    MainSec5,
    MainSec6,
  },
  methods: {
    updateDotPosition(origin, destination) {
      const sectionIndex = destination.index;
      this.dotPosition = (sectionIndex / (this.totalSections - 1)) * 100;
    },
    handleFullPageLeave(origin, destination) {
      this.updateDotPosition(origin, destination);
      this.manageNavbarVisibility(destination.index);
    },
    manageNavbarVisibility(sectionIndex) {
      if (sectionIndex === this.totalSections - 1) {
        this.hideNavbar = true;
        this.$refs.fullpage.api.setAllowScrolling(false, "down");
      } else {
        this.hideNavbar = false;
        this.$refs.fullpage.api.setAllowScrolling(true);
      }
    },
    handleAgreeModalOnUpdate(newAgreeModalOn) {
      this.agreeModalOn = newAgreeModalOn;
      this.toggleScrolling(!newAgreeModalOn);
    },
    toggleAgreeModal() {
      this.agreeModalOn = !this.agreeModalOn;
      this.toggleScrolling(!this.agreeModalOn);
    },
    toggleScrolling(enable) {
      if (enable) {
        this.$refs.fullpage.api.setAllowScrolling(true);
        document.removeEventListener('touchmove', this.preventScroll, { passive: false });
        document.removeEventListener('wheel', this.preventScroll, { passive: false });
      } else {
        this.$refs.fullpage.api.setAllowScrolling(false);
        document.addEventListener('touchmove', this.preventScroll, { passive: false });
        document.addEventListener('wheel', this.preventScroll, { passive: false });
      }
    },
    preventScroll(event) {
      const scrollableParagraph = document.getElementById('scrollable-paragraph');
      if (scrollableParagraph && !scrollableParagraph.contains(event.target)) {
        event.preventDefault();
      }
    },
    setFullHeight() {
      const mainSections = document.querySelectorAll('#main__section');
      if (window.innerWidth < 1201) {
        mainSections.forEach(section => {
          section.style.height = `${window.innerHeight}px`;
        });
      } else {
        mainSections.forEach(section => {
          section.style.height = '';
        });
      }
    },
    updateFullPage() {
      if (this.$refs.fullpage && this.$refs.fullpage.api) {
        this.$refs.fullpage.api.reBuild();
      }
    }
  },
  mounted() {
    this.setFullHeight();
    window.addEventListener('resize', () => {
      this.setFullHeight();
      this.updateFullPage();
    });
    this.toggleScrolling(true);
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.setFullHeight();
      this.updateFullPage();
    });
  }
};
</script>

<style>
.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
</style>
